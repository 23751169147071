import { createRouter, createWebHistory } from "vue-router";
// import Home from '../views/Home.vue'
// import Login from '../views/Login.vue'
import Store from "../store/store";
import PersistentStore from "../store/persistent";

const routes = [
  // {
  //   path: '/',
  //   name: 'Login',
  //   component: Login,
  // },
  {
    path: "/login",
    name: "Login",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),

    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/logout",
    name: "Logout",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Logout.vue"),

    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/signup",
    name: "SignUp",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SignUp.vue"),

    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/confirmregistration",
    name: "ConfirmRegistration",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ConfirmRegistration.vue"),

      meta: {
        requiresAuth: false,
        userRoles: ["admin"],
      },
  },
  {
    path: "/search",
    alias: "/",
    name: "Search",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Search.vue"),

    meta: {
      requiresAuth: true,
      userRoles: ["user", "student", "uploader", "admin"],
    },
  },
  {
    path: "/favorites",
    name: "Favorites",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Favorites.vue"),

    meta: {
      requiresAuth: true,
      userRoles: ["user", "student", "uploader", "admin"],
    },
  },
  {
    path: "/imageeditor",
    name: "ImageEditor",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ImageEditor.vue"),

    meta: {
      requiresAuth: false,
      userRoles: ["user", "student", "uploader", "admin"],
    },
  },
  {
    path: "/admin",
    name: "Admin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Admin.vue"),

    meta: {
      requiresAuth: true,
      userRoles: ["admin"],
    },
  },
  {
    path: "/upload",
    name: "Upload",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Upload.vue"),

    meta: {
      requiresAuth: true,
      userRoles: ["uploader", "admin"],
    },
  },
];

// const scrollBehavior = (to, from, savedPosition) => {
//   console.log(" scrollBehavior to: " + to)
//   console.log(" scrollBehavior from: " + from)
//   console.log(" scrollBehavior savedPosition: " + savedPosition)
//  if (savedPosition) {
//    return savedPosition
//  } else {
//    return { x: 0, y: 0 }
//  }
// }

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // scrollBehavior
});

const store = Store;
const persistentStore = PersistentStore;

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {

    persistentStore.methods.init();     


    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!persistentStore.state.loggedIn) {
      next({ name: "Login" });
    } else {
      if (to.matched.some((record) => record.meta.userRoles.includes(persistentStore.state.userRole))) {
        next(); // go to wherever I'm going
      } else {
        next({ name: "Login" });
      }
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }

  // We close the sliding menu after navigation happens
  setTimeout(function() {
    store.methods.setSlidingMenuVisible(false);
  }, 800);
});

export default router;
