<template>
  <div>
    <div id="profilMenuRoot" v-bind:class="{ verticalMenu: isVerticalMenu, lightTheme: lightTheme}">
  
   <!-- <NavItem label="Profilom" iconClass="fas fa-fw fa-user-cog " routerLink="/editprofile"  />  -->
      <NavItem  v-if="persistentStore.state.loggedIn" label="Kijelentkezés" iconClass="fas fa-fw fa-sign-out-alt" routerLink="/logout"  /> 
      <NavItem  v-else label="Bejelentkezés" iconClass="fas fa-fw fa-sign-in-alt" routerLink="/login"  /> 
         

    <!--    <router-link to="/editprofile">
        <div class="navItem">
          <i class="fas fa-user-cog navIcon"></i>
          <div class="navLabel">Profilom</div>
        </div>
      </router-link> -->

      <!--   <router-link to="/logout">
        <div class="navItem">
          <i class="fas fa-sign-out-alt navIcon"></i>
          <div class="navLabel">Kijelentkezés</div>
        </div>
      </router-link>-->
    </div>
  </div>
</template>

<script>

import NavItem from '@/components/NavItem.vue'
import PersistentStore from "../store/persistent";

export default {
  props: {
    isVerticalMenu: { type: Boolean, required: true },
        lightTheme: { type: Boolean, required: false, default: false },
  },
  components: {
    NavItem
  },
  setup(props) {

    const persistentStore = PersistentStore;


    return { props, persistentStore };
  },
};
</script>

<style scoped>
#profilMenuRoot {
  /*padding: 30px;*/
  /*background-color: chocolate;*/
  display: grid;
  grid-template-columns: 1fr 1fr;

  /*grid-gap: 10px;*/

  /*align-content: center;*/
  border-width: 0 0 0 0.3vh;
  border-style: solid;
  border-color: var(--light_text_color);

  /*background-color: brown;*/
}

#profilMenuRoot.verticalMenu {
  grid-template-columns: 1fr;
  grid-auto-rows: 8.5vh;/*var(--height_vertical_menu_items);*/

  border-width: 0 0 0 0;
}


</style>