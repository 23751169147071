<template>
  <BubbleWindowBase
    v-bind:isVisible="store.state.profilBubbleWindowVisible"
    v-on:hideRequested="store.methods.setProfilBubbleWindowVisible(false)"
  >
    <ProfilMenu
      id="profilMenu"
      v-bind:isVerticalMenu="true"
      lightTheme="true"
    />
  </BubbleWindowBase>
</template>

<script>
import { watch } from "vue";
import BubbleWindowBase from "@/components/BubbleWindowBase.vue";
import ProfilMenu from "@/components/ProfilMenu.vue";

import Store from "../store/store"
import PersistentStore from "../store/persistent"

export default {
  components: {
    BubbleWindowBase,
    ProfilMenu
  },



  setup() {
      const store = Store;
      const persistentStore = PersistentStore;


watch(
      () => persistentStore.state.loggedIn,
      (newValue) => {
        if (newValue == false) { 
          store.methods.setProfilBubbleWindowVisible(false)
        }
      } 
    ); // {flush: post} only triggers callback after DOM update


    return {store};
  },

      
};
</script>

<style scoped>
#profilMenu {
  display: grid;

  padding-top: 5vh;
}
</style>