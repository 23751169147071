<template>
  <div>
  
      <div v-if="isVisible" id="base" @click="emit('hideRequested')">
        <div id="shader"></div>
      </div>
   


      <div v-if="isVisible" id="window" key="1">
        <div id="content">
          <div>
            <slot> </slot>
          </div>
        </div>
      </div>

  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    isVisible: Boolean,
  },
  emits: ["hideRequested"],
  setup(props, { emit }) {
    const visible = ref(true);
    const baseVisible = ref(true);

    function hide() {
      console.log("hide");
      // isVisible.value = false;
    }

    return { props, hide, visible, baseVisible, emit };
  },
};
</script>

<style scoped>
#base {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: grid;
  z-index: 10000000000;
}

#shader {
  margin-top: var(--height_header);
  background-color: rgba(0, 0, 0, 0.68);
  
}

#window {
  display: grid;

  grid-template-rows: minmax(auto, min-content) 1fr;

  grid-template-areas:
    "header"
    "content";

  position: fixed;
  right: 1.5vh;
  top: var(--height_header);
  margin-top: 2.5vh;

  height: 30vh;
  width: 40vh;
  z-index: 100000000001;
}


#content {
  background-color: #ffffff;
  display: grid;

  border-radius: 2vh;

  grid-area: content;
}

/* The bubble's pointer rectangle */
#content:after {
  content: "";
  position: absolute;
  top: 0;
  left: 92%;
  width: 0;
  height: 0; 

  border: 2.5vh solid transparent;
  border-bottom-color: #ffffff;
  border-top: 0;
  margin-left: -2.5vh;
  margin-top: -1.5vh;
}
</style>