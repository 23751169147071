import { reactive/*, readonly*/ } from "vue";

const state = reactive({
    slidingMenuVisible: false,
    profilBubbleWindowVisible: false,
    colorChooserWindow_visible: false,
    colorChooserWindow_colorSet: "",
    extraImageAdderState: false,

    helpMenuVisible: false,
    helpMenuActualId: "upperTextBox",
    uploadedImageToEdit: undefined, // ide kerul az "upload.vue" feluleten szerkeszteni kivant (mar korabban feltoltott) kep
 
    searchPageNeedsUpdate: false, // ha torles, modositas, vagy hozzadas muvelet tortent, akkor a search page (keep-alive oldal) tartalmat frissitenunk kell

    actualFavoriteParentId: "",

    breadCrumbModel: []
}
)

const methods = {
    setSlidingMenuVisible(val) {
        state.slidingMenuVisible = val
    },
    setProfilBubbleWindowVisible(val) {
        state.profilBubbleWindowVisible = val
    },
    showColorChooserWindow(colorSet) {
        state.colorChooserWindow_colorSet = colorSet
        state.colorChooserWindow_visible = true
    }
}


export default {
    state: /*readonly(*/state/*)*/,
    methods,

 
}


//       // Save State to Persistent storage before leaving the page / closing the tab / browser
//       document.addEventListener('beforeunload', () =>
//       {
//         // localStorage.setItem('storeState2', /*JSON.stringify(store.state)*/  "ff")

//  localStorage.setItem('storeState2q', "ff")

//       })
 