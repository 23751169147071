<template>
  <ResponsiveDiv>
    <div class="header">
      <!--  <div id="menuButton" v-if="persistentStore.state.loggedIn">   -->
        <div id="menuButton"> 
        <i
          id="menuButtonIcon"
          class="fas fa-bars"
          @click="store.methods.setSlidingMenuVisible(true)"
        > 
        </i>
      </div>

      <div id="logo" @click="router.push('search')">
        VERBALIO<sup>(beta)</sup>
      </div>

      <!-- <div id="logo">
        KÉK
        <span class="logoSubText">KÉPTÁR</span>
      </div> -->

      <!-- <ColorChooserWindow
    id="colorChooserWindow"
      :isVisible="store.state.colorChooserWindow_visible"
      :colorSet="store.state.colorChooserWindow_colorSet"
     
      @hideRequested="store.state.colorChooserWindow_visible = false"          
    />  -->

  <!--    <NavigationMenu id="navigationMenu" v-if="persistentStore.state.loggedIn"> -->
    <NavigationMenu id="navigationMenu">
      </NavigationMenu>

      <div id="profil">
        <div
          id="profilButton"
          @click="
        profilButtonClicked        
          "
          v-bind:class="{ active: store.state.profilBubbleWindowVisible }"
        >
          <div id="profilLabel" v-if="persistentStore.state.loggedIn">{{ persistentStore.state.userEmail.split('@')[0] }}</div>
          <div id="profilLabel" v-else-if="router.currentRoute.value.name !='Login'">Bejelentkezés</div>


          <i id="profilButtonIcon" class="fas fa-user"> </i>
        </div>
      </div>

      <div id="headerSeparator"></div>
    </div>

    <div id="extraImageAdderHeader" v-if="store.state.extraImageAdderState">

      <div id="extraImageAdderCancelButtonContainer">
        
        <div id="extraImageAdderCancelButton" @click="cancelExtraImageAdderState">
        
    Mégse

        </div>

        </div>

      Melyik képet adjuk hozzá?
    </div>

    <div class="container">
      <div class="content">
 
          <!-- <router-view :key="$route.fullPath"></router-view> -->
 

        <router-view v-slot="{ Component  }">
          <keep-alive include="Search, Favorites">
            <component :is="Component" :key="$route.fullPath"></component>
          </keep-alive>

        

 
        </router-view>
      </div>
    </div>

    <SlidingMenu> </SlidingMenu>
    <ProfilBubbleWindow> </ProfilBubbleWindow>

    <!-- <div id="endOfDocument">
      

    </div> -->
  </ResponsiveDiv>
</template>

<script>
import { useRouter } from "vue-router";

import { /*ref,*/ onMounted /*onBeforeUnmount*/ } from "vue";
import Store from "./store/store";
import CanvasStore from "./store/canvas";
import PersistentStore from "./store/persistent";
import NavigationMenu from "@/components/NavigationMenu.vue";
import ResponsiveDiv from "@/components/ResponsiveDiv.vue";
import SlidingMenu from "@/components/SlidingMenu.vue";
import ProfilBubbleWindow from "@/components/ProfilBubbleWindow.vue";
// import ColorChooserWindow from "@/components/ColorChooserWindow.vue";

export default {
  components: {
    NavigationMenu,
    ResponsiveDiv,
    SlidingMenu,
    ProfilBubbleWindow,
    // ColorChooserWindow,
  },
  setup() {
    const router = useRouter();
    const store = Store;
    const persistentStore = PersistentStore;
    const canvasStore = CanvasStore;
    // const colorChooserWindowVisible = ref("true");

    onMounted(() => {
      console.log("App onmoutned");
      // canvasStore.defaultState = canvasStore.state

      // Object.assign(canvasStore.defaultState, canvasStore.state);
      console.log(canvasStore.state);

      //      var copiedState = {...canvasStore.state}

      //      console.log(copiedState)
      //      copiedState.configText.lowerText._upperCase = true

      // console.log(copiedState.configText.lowerText)

      //      Object.assign(canvasStore.state, copiedState)

      //canvasStore.state = canvasStore.defaultState

      //  // Restore State from Persistent storage
      //  var savedStoreState = localStorage.getItem('storeState')

      //  if(savedStoreState != null)
      //  {
      //    store.state = JSON.parse(savedStoreState)
      //  }

      //       // Save State to Persistent storage before leaving the page / closing the tab / browser
      //       document.addEventListener('beforeunload', () =>
      //       {
      //         // localStorage.setItem('storeState2', /*JSON.stringify(store.state)*/  "ff")

      //  localStorage.setItem('storeState2q', "ff")

      //       })
    });

    // onBeforeUnmount(() =>
    // {
    //   // Save State to Persistent storage
    //   // localStorage.setItem('storeState', JSON.stringify(store.state))

    //   localStorage.setItem('closed', 'true')
    // })


function cancelExtraImageAdderState()
{
store.state.extraImageAdderState = false
      router.push("imageeditor");
}

function profilButtonClicked()
{
  if(persistentStore.state.loggedIn)
  {
  store.methods.setProfilBubbleWindowVisible(
              !store.state.profilBubbleWindowVisible
            )
          }
          else
          {
            router.push("login");
          }
}


    return {
      store,
      persistentStore,
      router,
      cancelExtraImageAdderState,
      profilButtonClicked,
      //  colorChooserWindowVisible
    };
  },
};
</script>

<!-- GLOBAL STYLE -->
<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;*/

  /* font-family: "Roboto", sans-serif;*/

  font-family: "Arial";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  /*border-width: 10px;
  border-style: solid;*/
}
</style>

<!-- LOCAL STYLE -->
<style scoped>
.header {
  height: var(--height_header);
  width: 100vw;
  background-color: var(--primary_color);
  /* backdrop-filter: blur(10px);
 -webkit-backdrop-filter: blur(10px);*/
  position: fixed;
  display: grid;

  z-index: 999999999999999999;

  grid-template-columns: 1fr /*minmax(auto, min-content)*/ 1fr 1fr;
  grid-template-rows: 1fr minmax(auto, min-content);
  grid-template-areas:
    "logo navigationMenu profil"
    "headerSeparator headerSeparator headerSeparator ";
}

/*.header.portrait {
  height: 10vh;
}*/

#responsive.tablet .header {
  grid-template-columns: 1fr 1fr 1fr;
  /*background-color: goldenrod;*/
  grid-template-areas:
    "menuButton logo ."
    "headerSeparator headerSeparator headerSeparator ";
}


#responsive.mobile .header {
  grid-template-columns: 1fr 1fr 1fr;
  /*background-color: goldenrod;*/
  grid-template-areas:
    "menuButton logo ."
    "headerSeparator headerSeparator headerSeparator ";
}

#logo {
  grid-area: logo;
  display: grid;

  align-content: center;
  /*justify-content: start;*/

  grid-template-columns: minmax(auto, min-content) minmax(auto, min-content);

  padding-left: var(--padding_left_main_logo);
  gap: 0.5vh;

  font-size: var(--font_size_large);
  color: var(--light_text_color);
  font-weight: bold;

  user-select: none;
}

#responsive.tablet #logo {
  justify-content: center;
}
#responsive.mobile #logo {
  justify-content: center;
}

.logoSubText {
  font-weight: 300;
  /*background-color: forestgreen;*/
}

#menuButton {
  grid-area: menuButton;

  /*background-color: fuchsia;*/
  display: none;

  /*align-content: center;*/
  grid-template-columns: minmax(auto, min-content);
}

#responsive.tablet #menuButton {
  display: grid;
  /*background-color: green;*/
}

#responsive.mobile #menuButton {
  display: grid;
  /*background-color: green;*/
}

#menuButtonIcon {
  /*background-color: gold;*/
  display: grid;
  align-content: center;
  min-width: var(--height_header);
  font-size: 4.5vh;
  /*color: var(--secondary_color);*/

  color: var(--light_text_color);
}

#menuButtonIcon:hover {
  background-color: var(--secondary_color);
}

#navigationMenu {
  grid-area: navigationMenu;
  display: grid;
}

#responsive.tablet #navigationMenu {
  display: none;
}

#responsive.mobile #navigationMenu {
  display: none;
}

#extraImageAdderHeader {
  width: 100vw;
  height: calc(var(--height_header) - var(--height_header_separator));

  user-select: none;
  background-color: red;
  color: #ffffff;
  position: fixed;

  top: 0;
  left: 0;
  z-index: 99999999999999;

  display: grid;
  align-content: center;
  font-size: var(--font_size_large);
  font-weight: bold;
}

#extraImageAdderCancelButtonContainer
{

position: absolute;

  top:0px;
  left:1vw;
  height: 100%;
  width: 10vw;


 

  display: grid;

  grid-template-columns: 1fr;

  align-content: center;
  justify-content: center;

}

#extraImageAdderCancelButton
{
  border-width: 0.1em;
  border-color: white;
  border-style: solid;
 
 padding: 0.3em;
 
 /* font-weight: normal; */
}

#extraImageAdderCancelButton:hover
{
  
 color: red;
 background-color: white;
  
}


#profil {
  grid-area: profil;

  display: grid;

  justify-content: end;
  align-content: stretch;

  /* background-color: darkmagenta;*/
}

#responsive.tablet #profil {
  display: none;
}
#responsive.mobile #profil {
  display: none;
}

#profilButton {
  display: grid;
  grid-template-columns: max-content min-content;
  /*height: var(--height_header);*/
  align-content: center;
  /*background-color: seagreen;*/
}

#profilLabel {
  color: var(--light_text_color);
  font-size: var(--font_size_normal);
  user-select: none;
  /*background-color: darkorange;*/
  display: grid;

  justify-content: end;
  align-content: center;

  padding-right: 1vh;
  padding-left: var(--padding_left_main_logo);
  
  max-width: 20vw;

  text-overflow: ellipsis;
 overflow: hidden; 
  white-space: nowrap;

}

#profilButtonIcon {
  background-color: var(--light_text_color);
  color: var(--primary_color);

  font-size: var(--font_size_normal);

  height: 5.3vh;
  width: 5.3vh;

  border-radius: 50%;

  display: grid;
  align-content: center;

  margin-right: var(--padding_left_main_logo);

  border-style: solid;
  border-width: 0.3vh;
  box-sizing: border-box;
  border-color: var(--light_text_color);
}

#profilButton:hover #profilButtonIcon,
#profilButton.active #profilButtonIcon {
  background-color: var(--secondary_color);
  color: var(--light_text_color);
}

#headerSeparator {
  background-color: var(--secondary_color);
  grid-area: headerSeparator;
  height: var(--height_header_separator);
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  /*grid-gap: 10px;*/
  padding-top: 10vh;

  /* overflow-y: auto; */
}

.content {
  background-color: var(--background_color_content);

  min-height: var(--height_content);
  /*height: 90vh;*/
  /*overflow-y: scroll;*/
}

/* #colorChooserWindow
{
   z-index: 9999999999999999999;
}   */

/*
@media screen and (max-width: 600px) {
  body {
    background-color: olive;
  }
}*/
</style>
