<template>
  <ModalWindowBase
    id="modalWindowBase"
    v-bind:isVisible="store.state.slidingMenuVisible"
    v-on:hideRequested="store.methods.setSlidingMenuVisible(false)"
  >
    <div id="container">
      <div id="navigationBox">
        <NavigationMenu id="navigationMenu" v-bind:isVerticalMenu="true">
        </NavigationMenu>
      </div>

      <div id="profilBox">
        <div id="profilSeparator"></div>
        <div id="profilLabel"  v-if="persistentStore.state.loggedIn">Üdv {{ persistentStore.state.userEmail.split('@')[0]}}!</div>
        
        <ProfilMenu id="profilMenu" v-bind:isVerticalMenu="true"> </ProfilMenu>
      </div>
    </div>
  </ModalWindowBase>
</template>

<script>
import ModalWindowBase from "@/components/ModalWindowBase.vue";
import NavigationMenu from "@/components/NavigationMenu.vue";
import ProfilMenu from "@/components/ProfilMenu.vue";

import Store from "../store/store";
import PersistentStore from "../store/persistent";

export default {
  components: {
    ModalWindowBase,
    NavigationMenu,
    ProfilMenu,
  },

  setup() {
    const store = Store;
    const persistentStore = PersistentStore;

    return {
      store,
      persistentStore,
    };
  },
};
</script>

<style scoped>
#container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-content: stretch;

  /*gap: 20vh;*/




  background-color: var(--primary_color);
}

#navigationBox {
  /* background-color: blueviolet;*/
}

#navigationMenu {
  display: grid;

  /*padding-top: 5vh;*/
}

#profilBox {
  /* background-color: cornsilk;*/

  align-content: flex-end;

  display: grid;
}

#profilSeparator {
  height: 0.2vh;
  background-color: var(--secondary_color);
}

#profilLabel {
  color: var(--light_text_color);
  font-size: var(--font_size_normal);
  /*font-weight: 600;*/
  display: grid;
  justify-content: start;
  padding: 2vh;
  user-select: none;
}

#profilMenu {
  display: grid;

  padding-bottom: 2vh;
}
</style>