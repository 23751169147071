<template>
  <div
    id="responsive"
    v-bind:class="{ portrait: isPortrait, mobile: isMobile, tablet: isTablet }"
  >
    <slot> </slot>
  </div>
</template>

<script>

import { ref } from "vue";

export default {
  setup() {
    const isMobile = ref(false);
    const isTablet = ref(false);
    const isPortrait = ref(false);

    windowResizeEventHanler();

    window.addEventListener("resize", windowResizeEventHanler);

    function windowResizeEventHanler() {
      isTablet.value = window.innerWidth > 800 && window.innerWidth <= 1024;
      isMobile.value = window.innerWidth <= 800;
      isPortrait.value = window.innerWidth < window.innerHeight;
    }

    return { isMobile, isPortrait, isTablet };
  },
};
</script>


<style>
</style>