<template>
  <router-link v-bind:to="routerLink">
    <div class="navItem">
      <i class="navIcon" v-bind:class="iconClass"></i>
      <div class="navLabel">{{ label }}</div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    iconClass: { type: String, required: true },
    label: { type: String, required: true },
    routerLink: { type: String, required: true },
  },
};
</script>

<style scoped>
.navItem {
  display: grid;
  grid-template-columns: minmax(auto, min-content) minmax(auto, min-content);
  /* grid-template-columns:   11fr 8fr;*/
  /*grid-template-columns: minmax(min-content, 2fr) minmax(min-content, 8fr);*/
  /*align-content: center;*/

  padding-top: 0.5vh;

  column-gap: 1.5vh;
  padding-left: 1vh;
  padding-right: 1vh;
  /*background-color: tomato;*/
}

.verticalMenu .navItem {
  column-gap: 4vh;
}

.navLabel {
  display: grid;
  align-content: center;
  justify-content: start;
  text-transform: uppercase;
  font-size: var(--font_size_normal);
  /*background-color: teal;*/
}
/*
.verticalMenu .navLabel {
  font-weight: 600;
}*/

.navIcon {
  font-size: var(--icon_size_normal);
  display: grid;
  align-content: center;
  /*background-color: tomato;*/
}

a {
  /*font-size: 3em;*/

  color: var(--light_text_color);
  text-decoration: none;
  user-select: none;
  display: grid;
  justify-content: center;
  align-content: center;

  border-width: 0 0.3vh 0 0;
  border-style: solid;
  border-color: var(--light_text_color);
}

.verticalMenu a {
  border-width: 0 0 0 0;
  letter-spacing: 0.2vh;
  /*color: var(--dark_text_color);*/

  justify-content: left;
  padding-left: 2.5vh;
}

.lightTheme a {
  color: var(--dark_text_color);
}

a.router-link-exact-active {
  background-color: var(--secondary_color);
}

.lightTheme a.router-link-exact-active {
   color: var(--light_text_color);
}
/*
.verticalMenu a.router-link-exact-active {
  background-color: bisque;
}
*/
a:hover:not(.router-link-exact-active) {
  background-color: var(--hover_color);
  /*color: var(--dark_text_color); */
}

.lightTheme a:hover:not(.router-link-exact-active) {
   color: var(--light_text_color);
}
/*
.verticalMenu a:hover:not(.router-link-exact-active) {
  background-color: #b1b1b1;
 
}*/
</style>