<template>
<div>
  <transition name="base" appear>
    <div v-if="isVisible" id="base" @click="emit('hideRequested')"></div>
  </transition>

  <transition
    name="window"
    @before-enter="baseVisible = true"
    @after-leave="baseVisible = false"
    appear
  >
    <div v-if="isVisible" id="window" key="1">
      <div id="header">
        <div id="backButton" @click="emit('hideRequested')">
          <i id="backButtonIcon" class="fas fa-arrow-left"></i>
        </div>
        <div id="title">Menü</div>

        <div id="headerSeparator"></div>
      </div>

      <div id="content">
        
        <slot> </slot>
        
      </div>
    </div>
  </transition>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    isVisible: Boolean,
  },
  emits: ["hideRequested"],
  setup(props, { emit }) {
    const visible = ref(true);
    const baseVisible = ref(true);

    function hide() {
      console.log("hide");
      // isVisible.value = false;
    }

    return { props, hide, visible, baseVisible, emit };
  },
};
</script>

<style scoped>
#base {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

 
}

#window {
  background-color: white;
  display: grid;
 
  grid-template-rows: minmax(auto, min-content) 1fr;

  grid-template-areas:
    "header"
    "content";

  position: fixed;
  left: 0;
  top: 0;

  height: 100vh;
  width: 40vw;

z-index: 10000000000;
box-shadow: 10px 0px 20px 0px #333333;
}

#responsive.mobile #window{
width: 80vw;
}

.base-leave-active,
.base-enter-active,
.window-enter-active,
.window-leave-active {
  transition: all 0.5s ease;
}
.window-enter-from,
.window-leave-to {
  transform: translate(-100%, 0);
}

.base-enter-from,
.base-leave-to {
  opacity: 0;
}

#header {
  background-color: var(--primary_color);
  height: var(--height_header);
  display: grid;
  grid-area: header;
  grid-template-columns: minmax(auto, min-content) 1fr;
  grid-template-rows: 1fr minmax(auto, min-content);

  grid-template-areas:
    "backButton title"
    "headerSeparator headerSeparator";
}

#headerSeparator {
  background-color: var(--secondary_color);
  grid-area: headerSeparator;
  height: var(--height_header_separator);
}

#backButton {
  color: var(--light_text_color);
  display: grid;
  align-content: center;
  grid-area: backButton;
  min-width: var(--height_header);
  /*padding-left: 3vh;
  padding-right: 3vh;*/
}

#backButton:hover {
  background-color: var(--hover_color);
}

#backButtonIcon {
  font-size: 4.5vh;
}

#title {
  color: #ffffff;
    font-size: var(--font_size_large);

text-transform: uppercase;

  display: grid;
  align-content: center;

  grid-area: title;

  padding-right: var(--height_header);

  user-select: none;
}

#content {
  background-color: #c8c8c8;
  display: grid;

  align-content: stretch;
  
  grid-area: content;


}
</style>