<template>
  <div>
    <div id="nav" v-bind:class="{ verticalMenu: isVerticalMenu }">
      <NavItem
        label="Táblám"
        iconClass="fa fa-fw fa-grip-horizontal"
        routerLink="/favorites"
      />
      <NavItem
        label="Hozzáadás"
        iconClass="fa fa-fw fa-plus"
        routerLink="/search"
      />
      
      <NavItem
        label="Feltöltés"
        iconClass="fas fa-fw fa-cloud-upload-alt"
        routerLink="/upload"
        v-if="isUploader"
      />
      <!-- <NavItem label="Admin" iconClass="fas fa-fw fa-wrench" routerLink="/admin"  />  -->

      <!--<router-link to="/search">
        <div class="navItem">
          <i class="fa fa-search navIcon"></i>
          <div class="navLabel">Keresés</div>
        </div>
      </router-link>

      <router-link to="/favorites">
        <div class="navItem">
          <i class="fa fa-heart navIcon"></i>
          <div class="navLabel">Kedvenceim</div>
        </div>
      </router-link> -->
    </div>
  </div>
</template>

<script>
import NavItem from "@/components/NavItem.vue";
import PersistentStore from "../store/persistent";
import { ref, onMounted } from "vue";

export default {
  props: {
    isVerticalMenu: { type: Boolean, required: true },
  },
  components: {
    NavItem,
  },
  setup(props) {
    const persistentStore = PersistentStore;

    const isUploader = ref(false);
 
    onMounted(() => {
      persistentStore.methods.init();
      isUploader.value =
        persistentStore.state.userRole == "uploader" ||
        persistentStore.state.userRole == "admin";
    });

    return { props, isUploader };
  },
};
</script>

<style scoped>
#nav {
  /*padding: 30px;*/
  /*background-color: chocolate;*/
  display: grid;
  /* grid-template-columns: 1fr 1fr; */

  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  /*grid-gap: 10px;*/

  /*align-content: center;*/
  border-width: 0 0 0 0.3vh;
  border-style: solid;
  border-color: var(--light_text_color);
}

#nav.verticalMenu {
  grid-template-columns: 1fr;
  grid-auto-rows: var(--height_vertical_menu_items);

  border-width: 0 0 0 0;
}

.navItem {
  display: grid;
  grid-template-columns: minmax(auto, min-content) minmax(auto, min-content);
  /* grid-template-columns:   11fr 8fr;*/
  /*grid-template-columns: minmax(min-content, 2fr) minmax(min-content, 8fr);*/
  /*align-content: center;*/

  padding-top: 0.5vh;

  column-gap: 1.5vh;
  padding-left: 1vh;
  padding-right: 1vh;
  /*background-color: tomato;*/
}

#nav.verticalMenu .navItem {
  column-gap: 4vh;
}

.navLabel {
  display: grid;
  align-content: center;
  justify-content: start;
  text-transform: uppercase;
  font-size: var(--font_size_normal);
  /*background-color: teal;*/
}
/*
#nav.verticalMenu .navLabel {
  font-weight: 600;
}*/

.navIcon {
  font-size: var(--icon_size_normal);
  display: grid;
  align-content: center;
  /*background-color: tomato;*/
}

#nav a {
  /*font-size: 3em;*/

  color: var(--light_text_color);
  text-decoration: none;
  user-select: none;
  display: grid;
  justify-content: center;
  align-content: center;

  border-width: 0 0.3vh 0 0;
  border-style: solid;
  border-color: var(--light_text_color);
}

#nav.verticalMenu a {
  border-width: 0 0 0 0;

  /*color: var(--dark_text_color);*/

  letter-spacing: 0.3vh;

  justify-content: left;
  padding-left: 2.5vh;
}

#nav a.router-link-exact-active {
  background-color: var(--secondary_color);
}

/*#nav.verticalMenu a.router-link-exact-active {
  background-color: #ffffff;
}*/

#nav a:hover:not(.router-link-exact-active) {
  background-color: #3161a9;
  /*color: var(--dark_text_color); */
}
/*
#nav.verticalMenu a:hover:not(.router-link-exact-active) {
  background-color: #b1b1b1;
}*/
</style>
