import { reactive, watch } from "vue";

// // on loading the window, we restore the saved values from localStorage
// window.onload = function()
// {

//     let savedPersistentStore= localStorage.getItem("persistentStore")

//     if(savedPersistentStore != undefined)
//     {
//         //console.log(savedPersistentStore)

//         Object.assign(state, JSON.parse(savedPersistentStore))
//             //console.log(defaultState)
//     }

// }

// window.addEventListener('load', function()
// {

//     let savedPersistentStore= localStorage.getItem("persistentStore")

//     if(savedPersistentStore != undefined)
//     {
//         //console.log(savedPersistentStore)

//         Object.assign(state, JSON.parse(savedPersistentStore))
//             //console.log(defaultState)
//     }

// }
// , false);

let loaded = false;

function onLoadFunction() {
  if (!loaded) {
    let savedPersistentStore = localStorage.getItem("persistentStore");

    if (savedPersistentStore != undefined) {
      //console.log(savedPersistentStore)

      Object.assign(state, JSON.parse(savedPersistentStore));
      //console.log(defaultState)
    }

    loaded = true
  }
}

window.addEventListener("load", onLoadFunction, false);

const state = reactive({
  userId: "",
  userFullName: "",
  userRole: "user",
  userEmail: "user",
  token: "",
  loggedIn: "",

  checkedSearchFilters: [],
});

const methods = {
  init() {
    onLoadFunction();
  },
};

export default {
  state: state,
  methods,
};

watch(
  () => state,
  (newValue) => {
    localStorage.setItem("persistentStore", JSON.stringify(newValue));
  },
  { deep: true }
);
