import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import VueKonva from "vue3-konva";

const app = createApp(App);

app.use(router);
app.use(VueKonva);
app.mount('#app');
