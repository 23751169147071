import { reactive, readonly, watch } from "vue";
import {dataURLtoBlob, blobToDataURL} from "../components/DataURL.js"
// import { isNavigationFailure } from "vue-router";

// const defaultState = reactive()

const defaultImageResolution = 3000;

let defaultState = undefined;

// on loading the window, we make a copy of the state, so we can use it when restoring to default values
// window.onload = function()
// {

//     console.log("CANVAS STATE LOADED")

//     if(defaultState == undefined)
//     {
//     // defaultState = {...state}

//     // defaultState = JSON.stringify(Object.assign({}, state))

//     defaultState = JSON.stringify(state)

//     console.log(defaultState)
//     }
//     else
//     {
//         console.log("CanvasStore's defaultState already initialized!")
//     }
// }

function onLoadFunction() {
  console.log("CANVAS STATE LOADED");

  if (defaultState == undefined) {
    // defaultState = {...state}

    // defaultState = JSON.stringify(Object.assign({}, state))

    defaultState = JSON.stringify(state);

    console.log(defaultState);
  } else {
    console.log("CanvasStore's defaultState already initialized!");
  }
}

window.addEventListener("load", onLoadFunction, false);

const stateHistory = reactive({
  undoHappenedRecently: false,
  redoHappenedRecently: false,
  undoHistory: [],
  redoHistory: [],
});

const state = reactive({
  imageMetaData: {},

  stageSize: {
    originalWidth: 500,
    originalHeight: 500,

    width: 500,
    height: 500,
  },

  configStage: {
    width: 500,
    height: 500,
  },

  configBackgroundRect: {
    x: 0,
    y: 0,
    width: 500,
    height: 500,
    fill: "",

    stroke: "black",

    strokeWidth: 0,

    _rawFillColor: {
      type: "transparent",
      color: "#ffffff00",
      lightColor: false,
    },
    _rawStrokeColor: { type: "normal", color: "#000000", lightColor: false },

    _rawStrokeWidthInPx: 0,
  },

  configPaintBucket: {
    _on: false,
    _color: { type: "normal", color: "#00ff00", lightColor: false },
  },

  actualMainImage: { index: 0, type: "configMainImage" },

  actualMainImageIndex: 0,
  // topElementIndex: 0,
  actualMainImageIsOnTop: false,

  actualMainImageZIndex: 0,

  configGeneral: {
    _defaultOutlineColor: {
      type: "normal",
      color: "#000000",
      lightColor: false,
    },
    _outlineColor: { type: "normal", color: "#000000", lightColor: false },
    _grayScale: false,
    _outline: false,
  },

  configMainImage: [
    {
      image: null,
      x: 0,
      y: 0,
      width: 500,
      height: 500,

      draggable: false,

      rotation: 0,

      offsetX: 0,
      offsetY: 0,

      // zIndex:1000,
      // stroke: 'black',
      // strokeWidth: 4,
      _label: "kep",

      _originalImageData: undefined,

      _unmodifiedImageSrc: "",

      _zoom: 100,
      _dragX: 0,
      _dragY: 0,

      _flipped: false,
      scaleX: 1,
    },
  ],

  extraImageNameList: [],

  configExtraImages: [],

  configWaterMarkImage: {
    image: null,
    _originalSource: "",
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    visible: true,

    // stroke: 'black',
    // strokeWidth: 4,
  },

  configText: {
    upperText: {
      padding: 10,
      width: 500,
      /*height: 500,*/
      y: 0,
      x: 0,
      text: "",
      _rawText: "",
      _upperCase: false,
      fontSize: 39.9,
      fill: "#000000",
      _rawFillColor: { type: "normal", color: "#000000", lightColor: false },
      _rawFontSizeInPt: 30,
      _paintedHeight: 0,
      align: "center",
      // verticalAlign: 'top',
      fontFamily: "Arial",
      visible: true,
      /* fillEnabled: true,
             fillPriority: 'linear-gradient',
             fillLinearGradientStartPoint: { x: 0, y: 0 },
             fillLinearGradientEndPoint: { x: 60, y: 35},
             fillLinearGradientColorStops: [0, 'red', 1, 'yellow']*/

      firstLetter: {
        /*width: 500,*/
        /*height: 500,*/
        padding: 10,
        x: 0,
        y: 0,
        text: "",
        fontSize: 39.9,
        align: "left",
        fontFamily: "Arial",
        fillEnabled: true,
        fill: "#ff0000",
      },
    },
    lowerText: {
      padding: 10,
      width: 500,
      /*height: 500,*/
      y: 445,
      x: 0,
      text: "",
      _rawText: "",
      _upperCase: false,
      fontSize: 39.9,
      fill: "#000000",
      _rawFillColor: { type: "normal", color: "#000000", lightColor: false },
      _rawFontSizeInPt: 30,
      _paintedHeight: 0,
      align: "center",
      //verticalAlign: 'bottom',
      fontFamily: "Arial",
      visible: true,
      /* fillEnabled: true,
             fillPriority: 'linear-gradient',
             fillLinearGradientStartPoint: { x: 0, y: 0 },
             fillLinearGradientEndPoint: { x: 60, y: 35},
             fillLinearGradientColorStops: [0, 'red', 1, 'yellow']*/

      firstLetter: {
        padding: 10,
        /*width: 500,*/
        /*height: 500,*/
        x: 0,
        y: 445,
        text: "",
        fontSize: 39.9,
        align: "left",
        fontFamily: "Arial",
        fillEnabled: true,
        fill: "#ff0000",
      },
    },
  },
});

const methods = {
  // getDescendantProp(obj, desc) {
  //   var arr = desc.split(".");
  //   while(arr.length && (obj = obj[arr.shift()]));
  //   return obj;
  // },

  saveToFavorites()
  {
    console.log("save to favorites")
   
    console.log(JSON.stringify(state))
    console.log(state.configMainImage[0].image.src)

    let blob = dataURLtoBlob(state.configMainImage[0].image.src)
    console.log(blob)
    blob.text().then(text =>  
    
    state.configMainImage[0]._dataURL = text
    );
  
    blobToDataURL(blob, function(dataurl){
      console.log(dataurl);
      state.configMainImage[0].image.src = dataurl
  });
  },

  // ez a fgv. visszaadja a main image src-ket listaban
  // ha dataurl, akkor dataurl megjelolest hasznal
  getMainImageSourcesList()
  {
    let imageSources = []
    for(let i = 0; i < state.configMainImage.length; i++)
    {
      if(state.configMainImage[i].image.src.startsWith('http'))
      {
        imageSources.push(state.configMainImage[i].image.src)
      }
      else
      {
        imageSources.push("dataurl")
      }
    }

    return imageSources
  },

  // ez a fgv. visszaadja a dataurl-ben levo kepeket blobkent egy listaban
  getMainImageBlobList()
{
  let imageBlobList = []
  for(let i = 0; i < state.configMainImage.length; i++)
  {
 
    if(!state.configMainImage[i].image.src.startsWith('http'))
    {
      imageBlobList.push(dataURLtoBlob(state.configMainImage[i].image.src))
    }
  }

  return imageBlobList
},

  getStringifiedState()
  {
    return JSON.stringify(state)
  },




  

  saveToHistory(propertyName, undoFunction, value) {
    let historyObject = {
      propertyName: propertyName,
      undoFunction: undoFunction,
      value: value,
    };

    if (!stateHistory.undoHappenedRecently) {
      if (!stateHistory.redoHappenedRecently) {
        stateHistory.redoHistory = [];
      } else {
        stateHistory.redoHappenedRecently = false;
      }

      if (
        propertyName == "state.configText.upperText._rawFontSizeInPt" &&
        stateHistory.undoHistory.length > 0 &&
        stateHistory.undoHistory[stateHistory.undoHistory.length - 1]
          .propertyName == propertyName &&
        Math.abs(
          stateHistory.undoHistory[stateHistory.undoHistory.length - 1].value -
            value
        ) < 10
      ) {
        console.log("We don't save this undoFunction again...");
      } else if (
        propertyName == "state.configText.lowerText._rawFontSizeInPt" &&
        stateHistory.undoHistory.length > 0 &&
        stateHistory.undoHistory[stateHistory.undoHistory.length - 1]
          .propertyName == propertyName &&
        Math.abs(
          stateHistory.undoHistory[stateHistory.undoHistory.length - 1].value -
            value
        ) < 10
      ) {
        console.log("We don't save this undoFunction again...");
      } else if (
        propertyName == "state.configBackgroundRect._rawStrokeWidthInPx" &&
        stateHistory.undoHistory.length > 0 &&
        stateHistory.undoHistory[stateHistory.undoHistory.length - 1]
          .propertyName == propertyName &&
        Math.abs(
          stateHistory.undoHistory[stateHistory.undoHistory.length - 1].value -
            value
        ) < 10
      ) {
        console.log("We don't save this undoFunction again...");
      } else if (
        propertyName ==
          "state[state.actualMainImage.type][state.actualMainImage.index]._zoom" &&
        stateHistory.undoHistory.length > 0 &&
        stateHistory.undoHistory[stateHistory.undoHistory.length - 1]
          .propertyName == propertyName &&
        Math.abs(
          stateHistory.undoHistory[stateHistory.undoHistory.length - 1].value -
            value
        ) < 10
      ) {
        console.log("We don't save this undoFunction again...");
      } else if (
        propertyName ==
          "state[state.actualMainImage.type][state.actualMainImage.index].rotation" &&
        stateHistory.undoHistory.length > 0 &&
        stateHistory.undoHistory[stateHistory.undoHistory.length - 1]
          .propertyName == propertyName &&
        Math.abs(
          stateHistory.undoHistory[stateHistory.undoHistory.length - 1].value -
            value
        ) < 10
      ) {
        console.log("We don't save this undoFunction again...");
      } else {
        stateHistory.undoHistory.push(historyObject);
      }
    } else {
      stateHistory.redoHistory.push(historyObject);
      stateHistory.undoHappenedRecently = false;
    }
  },

  saveImageBeforePaintBucketChange(imageIndex, oldValue, newValue) {
    // Save to history
    var undoFunction = function() {
      state.configMainImage[imageIndex].image = new Image();
      state.configMainImage[imageIndex].image.src = oldValue;

      // Save again with the new value (this will place it in redoHistory - similarly as how it happends with watched properties)
      methods.saveImageBeforePaintBucketChange(imageIndex, newValue, oldValue);

      // // redo function
      // var redoFunction = function() {
      //   state.configMainImage[imageIndex].image.src = newValue;
      // };
      // methods.saveToHistory(
      //   "state.configMainImage[imageIndex].image.src",
      //   redoFunction,
      //   newValue
      // );
      // //~redo function
    };

    let dataToSave = {
      imageIndex: imageIndex,
      oldValue: oldValue,
    };

    methods.saveToHistory("paintBucket", undoFunction, dataToSave);
    //~Save to hisory
  },

  undo() {
    stateHistory.undoHappenedRecently = true;
    console.log("undo");

    // console.log(stateHistory.undoHistory);

    let lastHistoryItem = stateHistory.undoHistory.pop();
    if (lastHistoryItem != undefined) {
      lastHistoryItem.undoFunction();

      // if(lastHistoryItem.propertyName == "paintBucket")
      // {
      //   setTimeout(function() {
      //     stateHistory.undoHistory = [];
      //     stateHistory.redoHistory = [];
      //     stateHistory.undoHappenedRecently=false
      //     stateHistory.redoHappenedRecently=false

      //   methods.saveImageBeforePaintBucketChange(lastHistoryItem.oldValue.imageIndex, state.configMainImage[lastHistoryItem.oldValue.imageIndex].image.src)

      // }, 500);
      // }
    }
    // let lastHistoryItem = JSON.parse(stateHistory.pop())
    // console.log(lastHistoryItem)

    // Object.assign(lastHistoryItem.configMainImage, state.configMainImage);
    // Object.assign(lastHistoryItem.configWaterMarkImage, state.configWaterMarkImage);
    // Object.assign(lastHistoryItem.configExtraImages, state.configExtraImages);

    // Object.assign(state, lastHistoryItem);
  },

  redo() {
    stateHistory.redoHappenedRecently = true;
    console.log("redo");

    let lastHistoryItem = stateHistory.redoHistory.pop();
    if (lastHistoryItem != undefined) {
      lastHistoryItem.undoFunction();
    }
  },

  undoAll() {
    stateHistory.undoAllInProgress = true;

    let currentStageSize = {
      width: state.stageSize.width,
      height: state.stageSize.height,
    };

    methods.loadNewImage(state.imageMetaData);

    methods.setStageSize(currentStageSize.width, currentStageSize.height);

    setTimeout(function() {
      stateHistory.undoHistory = [];
      stateHistory.redoHistory = [];
      stateHistory.undoHappenedRecently = false;
      stateHistory.redoHappenedRecently = false;
    }, 50);
  },

  getDefaultStateWithRecalculatedSizes() {
    var paresedDefaultState = JSON.parse(defaultState);

    methods.recalculateSizes(paresedDefaultState);

    return paresedDefaultState;
  },

  restoreDefaultState_upperText() {
    Object.assign(
      state.configText.upperText,
      methods.getDefaultStateWithRecalculatedSizes().configText.upperText
    );
  },

  restoreDefaultState_lowerText() {
    Object.assign(
      state.configText.lowerText,
      methods.getDefaultStateWithRecalculatedSizes().configText.lowerText
    );
  },

  restoreDefaultState_appearance() {
    var paresedDefaultState = JSON.parse(defaultState);

    state.configGeneral._outline = false;

    state.configGeneral._outlineColor =
      paresedDefaultState.configGeneral._outlineColor;

    state.configBackgroundRect._rawFillColor =
      paresedDefaultState.configBackgroundRect._rawFillColor;

    state.configBackgroundRect._rawStrokeColor =
      paresedDefaultState.configBackgroundRect._rawStrokeColor;

    state.configBackgroundRect._rawStrokeWidthInPx =
      paresedDefaultState.configBackgroundRect._rawStrokeWidthInPx;
  },

  restoreDefaultState_paintBucket() {
    var paresedDefaultState = JSON.parse(defaultState);

    Object.assign(
      state.configPaintBucket,
      paresedDefaultState.configPaintBucket
    );

    // let outlineStateToRestore = state.configGeneral._outline
    // let outlineColorToRestore = state.configGeneral._outlineColor

    // state.configGeneral._grayScale = false;
    // state.configGeneral._outline = false
    // state.configGeneral._outlineColor = paresedDefaultState.configGeneral._outlineColor

    // for(let i = 0; i < state.configMainImage.length; i++)
    // {
    //   methods.setMainImageSource(state.configMainImage[i]._unmodifiedImageSrc, i)
    // }

    // state.configMainImage[state.configMainImage.length-1].image.onload = function() {
    //   state.configGeneral._outline = outlineStateToRestore
    // state.configGeneral._outlineColor = outlineColorToRestore
    // };

    // setTimeout(function() {
    //   state.configGeneral._outline = outlineStateToRestore
    // state.configGeneral._outlineColor = outlineColorToRestore
    // }, 5);
  },

  restoreDefaultState_sizing() {
    state[state.actualMainImage.type][state.actualMainImage.index]._dragX = 0;
    state[state.actualMainImage.type][state.actualMainImage.index]._dragY = 0;
    state[state.actualMainImage.type][state.actualMainImage.index].x = 0;
    state[state.actualMainImage.type][state.actualMainImage.index].y = 0;
    state[state.actualMainImage.type][state.actualMainImage.index].offsetX = 0;
    state[state.actualMainImage.type][state.actualMainImage.index].offsetY = 0;

    state[state.actualMainImage.type][
      state.actualMainImage.index
    ].draggable = false;

    state[state.actualMainImage.type][
      state.actualMainImage.index
    ]._flipped = false;

    state[state.actualMainImage.type][state.actualMainImage.index]._zoom = 100;

    state[state.actualMainImage.type][state.actualMainImage.index].rotation = 0;

    // this.recalculateEveryImageSize()

    //state.configMainImage[state.actualMainImage]

    // if (state.actualMainImage.type == "configMainImage") {
    //   methods.recalculateImageSizes(state.actualMainImage.index);
    // } else if (state.actualMainImage.type == "configExtraImages") {
    //   methods.recalculateExtraImageSizes();
    // }
  },

  restoreDefaultState_extras() {
    state.configWaterMarkImage.visible = true;
    state.extraImageNameList = [];
    state.configExtraImages = [];
  },

  restoreDefaultState() {
    console.log("Restore default state");
    Object.assign(state, JSON.parse(defaultState));
    console.log(defaultState);
  },

  restoreActualMainImageToDefault() {
    let defaultMainImage = { index: 0, type: "configMainImage" };
    Object.assign(state.actualMainImage, defaultMainImage);
  },

  loadNewImage(image) {
    this.restoreDefaultState();

    state.imageMetaData = image;
    // console.log("Restore default state")
    // Object.assign(state, JSON.parse(defaultState))
    // console.log(defaultState)

    console.log(image);

    state.configMainImage[0]._label = image.synonyms[0]

      this.setMainImageSource(
        "verbalio_server/uploadedImages/" + image.photoId + "_raw.png"
      );

    methods.setWaterMarkImageSource("/watermarks/" + image.waterMarkPath);

    setTimeout(function() {
      stateHistory.undoHistory = [];
      stateHistory.redoHistory = [];
    }, 50);
  },

  loadSavedWork(image)
  {
   
    
    console.log("load saved work")
    console.log(image)
    console.log(JSON.parse(image.savedState))

    let paresedState = JSON.parse(image.savedState)
    let paresedMainImageList = JSON.parse(image.savedMainImageList)

    console.log(paresedState)

    console.log(paresedMainImageList)

    // uj image objektumot hozunk letre minden configmainimagenek
    for(let i = 0; i<paresedMainImageList.length; i++)
    {
      paresedState.configMainImage[i].image = new window.Image();
    }

    console.log(paresedState)

    console.log("load saved work...")



    // paresedState.configMainImage = new Array();
    // paresedState.configMainImage = JSON.parse(defaultState).configMainImage;

    //  console.log(paresedState)


    // uj image objektumot hozunk letre a vizjelnek
    paresedState.configWaterMarkImage.image = new window.Image();

    
    // uj image objektumot hozunk letre az osszes extraImagenek
    for(let i = 0; i<paresedState.configExtraImages.length; i++)
    {
      paresedState.configExtraImages[i].image = new window.Image();
      paresedState.configExtraImages[i].image.src = paresedState.configExtraImages[i]._originalSource
    }
 
    



     // beallitjuk a lementett allapotot
    Object.assign(state, paresedState);

    // beallitjuk a kepek forrasat
    // this.setMainImageSource(
    //   "verbalio_server/savedWords/" + image.photoId + "_0.png", 0
    // );


    // beallitjuk a vizjel sourceot
    methods.setWaterMarkImageSource(paresedState.configWaterMarkImage._originalSource)

    // beallitjuk a main image sourceokat
    for(let i = 0; i<paresedMainImageList.length; i++)
    {

     methods.setMainImageSource('verbalio_server/' + paresedMainImageList[i], i);
    }

 

  // nullazzuk az elozmenyeket
  setTimeout(function() {
    stateHistory.undoHistory = [];
    stateHistory.redoHistory = [];
  }, 50);




  },

  addImage(label, type, source) {
    let imageToAdd = {
      image: null,
      x: 0,
      y: 0,
      width: 500,
      height: 500,

      draggable: false,

      rotation: 0,

      offsetX: 0,
      offsetY: 0,

      // stroke: 'black',
      // strokeWidth: 4,
      _label: label,

      _type: type,

      _grayScale: false,
      _outline: false,

      _originalImageData: undefined,

      _unmodifiedImageSrc: "",

      _zoom: 100,
      _dragX: 0,
      _dragY: 0,

      _flipped: false,
      scaleX: 1,
    };

    state.configMainImage.push(imageToAdd);

    let index = state.configMainImage.length - 1;

    this.setMainImageSource(source, index);

    // Save to history
    var undoFunction = function() {
      methods.deleteImage(index);
    };

    methods.saveToHistory("addImage", undoFunction, "");
    //~Save to hisory
  },

  deleteImage(index) {
    let imageToDelete = {};

    Object.assign(imageToDelete, state.configMainImage[index]);

    //delete the item
    state.configMainImage.splice(index, 1);

    // Save to history
    var undoFunction = function() {
      state.configMainImage.splice(index, 0, imageToDelete);
    };

    methods.saveToHistory("deleteImage", undoFunction, "");
    //~Save to hisory

    console.log(state.configMainImage);
  },

  addExtraImage(image) {
    this.addImage(
      image.synonyms[0],
      "NormalImage",
      "verbalio_server/uploadedImages/" + image.photoId + "_raw.png"
    );
  },

  addExtraIcon(label, type, source) {
    this.addImage(label, "NormalImage", source);
  },

  setStageSize(width, height) {
    state.stageSize.width = width;
    state.stageSize.height = height;

    this.recalculateSizes(state);
  },

  recalculateSizes(selectedState) {
    console.log("recalculte sizes called");
    console.log(selectedState);

    const oldStageWidth = selectedState.configStage.width;
    const oldStageHeight = selectedState.configStage.height;

    const widthConversionRatio = state.stageSize.width / oldStageWidth;
    const heightConversionRatio = state.stageSize.height / oldStageHeight;

    selectedState.stageSize.heightConversionRatio = heightConversionRatio;
    selectedState.stageSize.widthConversionRatio = widthConversionRatio;

    // update canvas size
    selectedState.configStage.width = selectedState.stageSize.width;
    selectedState.configStage.height = selectedState.stageSize.height;

    // recalculate configBackgroundRect size
    selectedState.configBackgroundRect.width *= widthConversionRatio;
    selectedState.configBackgroundRect.height *= heightConversionRatio;

    // recalculate strokeWidth
    selectedState.configBackgroundRect.strokeWidth *= widthConversionRatio;

    // recalculate mainImage size
    for (let index = 0; index < selectedState.configMainImage.length; index++) {
      selectedState.configMainImage[index].x *= widthConversionRatio;
      selectedState.configMainImage[index].width *= widthConversionRatio;
      selectedState.configMainImage[index].y *= heightConversionRatio;
      selectedState.configMainImage[index].height *= heightConversionRatio;

      selectedState.configMainImage[index]._dragX *= widthConversionRatio;
      selectedState.configMainImage[index]._dragY *= heightConversionRatio;

      selectedState.configMainImage[index].offsetX *= widthConversionRatio;
      selectedState.configMainImage[index].offsetY *= heightConversionRatio;
    }

    // recalculate waterMarkImage
    selectedState.configWaterMarkImage.height *= heightConversionRatio;
    selectedState.configWaterMarkImage.width *= widthConversionRatio;
    selectedState.configWaterMarkImage.x *= widthConversionRatio;
    selectedState.configWaterMarkImage.y *= heightConversionRatio;

    // recalculate upperText width and fontSize and padding
    selectedState.configText.upperText.width *= widthConversionRatio;
    selectedState.configText.upperText.x *= widthConversionRatio;
    selectedState.configText.upperText.y *= heightConversionRatio;
    selectedState.configText.upperText.fontSize *= heightConversionRatio;
    selectedState.configText.upperText.padding *= heightConversionRatio;
    selectedState.configText.upperText._paintedHeight *= heightConversionRatio;

    // recalculate upperText.firstLetter x and fontSize
    selectedState.configText.upperText.firstLetter.y *= heightConversionRatio;
    selectedState.configText.upperText.firstLetter.x *= widthConversionRatio;
    selectedState.configText.upperText.firstLetter.fontSize *= heightConversionRatio;
    selectedState.configText.upperText.firstLetter.padding *= heightConversionRatio;

    // recalculate lowerText width and fontSize and padding
    selectedState.configText.lowerText.width *= widthConversionRatio;
    selectedState.configText.lowerText.x *= widthConversionRatio;
    selectedState.configText.lowerText.y *= heightConversionRatio;
    selectedState.configText.lowerText.fontSize *= heightConversionRatio;
    selectedState.configText.lowerText.padding *= heightConversionRatio;
    selectedState.configText.lowerText._paintedHeight *= heightConversionRatio;

    // recalculate lowerText.firstLetter x and fontSize
    selectedState.configText.lowerText.firstLetter.y *= heightConversionRatio;
    selectedState.configText.lowerText.firstLetter.x *= widthConversionRatio;
    selectedState.configText.lowerText.firstLetter.fontSize *= heightConversionRatio;
    selectedState.configText.lowerText.firstLetter.padding *= heightConversionRatio;

    console.log("recalculte sizes done");
    console.log(selectedState);
  },

  setMainImageSource(source, index = 0) {
    /*  state.configMainImage[0].width = state.configStage.width * 1
          state.configMainImage[0].height = state.configStage.height * 1
  
  
          state.configMainImage[0].x = (state.configStage.width - state.configMainImage[0].width) / 2
          state.configMainImage[0].y = (state.configStage.height - state.configMainImage[0].height) / 2*/

    console.log("set main image src");
    // if(state.configMainImage[index].image!=null)
    // {console.log(state.configMainImage[index].image.src)}

    state.configMainImage[index].image = new window.Image();
    state.configMainImage[index].image.src = source;

    state.configMainImage[index]._unmodifiedImageSrc = source;

    methods.recalculateImageSizes();
  },

  setWaterMarkImageSource(source) {
    
    state.configWaterMarkImage._originalSource = source

    state.configWaterMarkImage.image = new window.Image();
    
    state.configWaterMarkImage.image.onload = function() {
      methods.recalculateWaterMarkImageSize();
    };

    state.configWaterMarkImage.image.src = source;
  },

  recalculateEveryExtraImageSize() {
    var left_y = state.configBackgroundRect.strokeWidth / 2;
    var right_y = state.configBackgroundRect.strokeWidth / 2;

    for (var i = 0; i < state.configExtraImages.length; i++) {
      if (state.configExtraImages[i]._type == "icon") {
        var aspectRatio = 0.70645792563600782778864970645793;
        var padding = state.configStage.height * 0.01;

        state.configExtraImages[i].height =
          (state.configStage.height - state.configBackgroundRect.strokeWidth) *
          0.15;
        state.configExtraImages[i].width =
          state.configExtraImages[i].height * aspectRatio;

        state.configExtraImages[i].width *=
          state.configExtraImages[i]._zoom / 100;
        state.configExtraImages[i].height *=
          state.configExtraImages[i]._zoom / 100;

        if (state.configExtraImages[i]._location == "left") {
          state.configExtraImages[i].y = left_y + padding;
          state.configExtraImages[i].x =
            state.configBackgroundRect.strokeWidth / 2 + padding;

          left_y += state.configExtraImages[i].height + padding;
        } else if (state.configExtraImages[i]._location == "right") {
          state.configExtraImages[i].y = right_y + padding;
          state.configExtraImages[i].x =
            state.configStage.width -
            state.configBackgroundRect.strokeWidth / 2 -
            state.configExtraImages[i].width -
            padding;

          right_y += state.configExtraImages[i].height + padding;
        }
      } else if (state.configExtraImages[i]._type == "overlay") {
        state.configExtraImages[i].height =
          state.configStage.height - state.configBackgroundRect.strokeWidth;
        state.configExtraImages[i].width = state.configExtraImages[i].height;

        state.configExtraImages[i].width *=
          state.configExtraImages[i]._zoom / 100;
        state.configExtraImages[i].height *=
          state.configExtraImages[i]._zoom / 100;

        state.configExtraImages[i].x =
          (state.configStage.width - state.configExtraImages[i].width) / 2;

        state.configExtraImages[i].y =
          (state.configStage.height - state.configExtraImages[i].height) / 2;
      }

      state.configExtraImages[i].x += state.configExtraImages[i]._dragX;
      state.configExtraImages[i].y += state.configExtraImages[i]._dragY;

      // console.log(
      //   "recalculateExtraImageSizes " +
      //     state.configExtraImages[i]._name +
      //     "" +
      //     state.configExtraImages[i].y +
      //     "->w: " +
      //     state.configExtraImages[i].height
      // );
    }
  },

  // recalculateExtraImageSize(i) {
  //   var left_y = state.configBackgroundRect.strokeWidth / 2;
  //   var right_y = state.configBackgroundRect.strokeWidth / 2;

  //   if (state.configExtraImages[i]._type == "icon") {
  //     var aspectRatio = 0.70645792563600782778864970645793;
  //     var padding = state.configStage.height * 0.01;

  //     state.configExtraImages[i].height =
  //       (state.configStage.height - state.configBackgroundRect.strokeWidth) *
  //       0.15;
  //     state.configExtraImages[i].width =
  //       state.configExtraImages[i].height * aspectRatio;

  //     state.configExtraImages[i].width *=
  //       state.configExtraImages[i]._zoom / 100;
  //     state.configExtraImages[i].height *=
  //       state.configExtraImages[i]._zoom / 100;

  //     if (state.configExtraImages[i]._location == "left") {
  //       state.configExtraImages[i].y = left_y + padding;
  //       state.configExtraImages[i].x =
  //         state.configBackgroundRect.strokeWidth / 2 + padding;

  //       left_y += state.configExtraImages[i].height + padding;
  //     } else if (state.configExtraImages[i]._location == "right") {
  //       state.configExtraImages[i].y = right_y + padding;
  //       state.configExtraImages[i].x =
  //         state.configStage.width -
  //         state.configBackgroundRect.strokeWidth / 2 -
  //         state.configExtraImages[i].width -
  //         padding;

  //       right_y += state.configExtraImages[i].height + padding;
  //     }
  //   } else if (state.configExtraImages[i]._type == "overlay") {
  //     state.configExtraImages[i].height =
  //       state.configStage.height - state.configBackgroundRect.strokeWidth;
  //     state.configExtraImages[i].width = state.configExtraImages[i].height;

  //     state.configExtraImages[i].width *=
  //       state.configExtraImages[i]._zoom / 100;
  //     state.configExtraImages[i].height *=
  //       state.configExtraImages[i]._zoom / 100;

  //     state.configExtraImages[i].x =
  //       (state.configStage.width - state.configExtraImages[i].width) / 2;

  //     state.configExtraImages[i].y =
  //       (state.configStage.height - state.configExtraImages[i].height) / 2;
  //   }

  //   state.configExtraImages[i].x += state.configExtraImages[i]._dragX;
  //   state.configExtraImages[i].y += state.configExtraImages[i]._dragY;

  //   console.log(
  //     "recalculateExtraImageSizes " +
  //       state.configExtraImages[i]._name +
  //       "" +
  //       state.configExtraImages[i].y +
  //       "->w: " +
  //       state.configExtraImages[i].height
  //   );
  // },

  recalculateWaterMarkImageSize() {
    if (state.configWaterMarkImage.image != null) {
      var aspectRatio =
        state.configWaterMarkImage.image.width /
        state.configWaterMarkImage.image.height;

      /*var padding = state.configStage.height * 0.02*/

      // console.log("w lowerText._paintedHeight: " + state.configText.lowerText._paintedHeight)

      state.configWaterMarkImage.height =
        (state.configStage.height -
          state.configBackgroundRect.strokeWidth -
          state.configText.lowerText._paintedHeight / 2) *
        0.1;
      state.configWaterMarkImage.width =
        state.configWaterMarkImage.height * aspectRatio;

      state.configWaterMarkImage.x =
        state.configStage.width -
        state.configWaterMarkImage.width -
        state.configBackgroundRect.strokeWidth / 2 -
        state.configText.lowerText.padding; /* - padding*/
      state.configWaterMarkImage.y =
        state.configStage.height -
        state.configWaterMarkImage.height -
        state.configBackgroundRect.strokeWidth / 2 -
        state.configText.lowerText._paintedHeight -
        state.configText.lowerText.padding;
      /*- padding*/
    }
  },

  recalculateEveryImageSize() {
    for (let i = 0; i < state.configMainImage.length; i++) {
      this.recalculateImageSizes(i);
    }
  },

  recalculateImageSizes(index = 0) {
    //- state.configBackgroundRect.strokeWidth/2
    var imageHeight =
      state.configStage.height -
      state.configText.upperText._paintedHeight -
      (state.configText.upperText.visible
        ? state.configText.upperText.padding
        : 0) -
      state.configText.lowerText._paintedHeight -
      (state.configText.lowerText.visible
        ? state.configText.lowerText.padding
        : 0) -
      state.configBackgroundRect.strokeWidth;

    imageHeight *= state.configMainImage[index]._zoom / 100;

    state.configMainImage[index].width = imageHeight;
    state.configMainImage[index].height = imageHeight;

    state.configMainImage[index].x =
      (state.configStage.width - imageHeight) / 2;
    state.configMainImage[index].y =
      (state.configStage.height - imageHeight) / 2;

    if (state.configMainImage[index]._flipped) {
      state.configMainImage[index].scaleX = -1;
      state.configMainImage[index].x += imageHeight;
    } else {
      state.configMainImage[index].scaleX = 1;
    }

    state.configMainImage[index].y +=
      (state.configText.upperText._paintedHeight -
        state.configText.lowerText._paintedHeight) /
      2;

    state.configMainImage[index].x += state.configMainImage[index]._dragX;
    state.configMainImage[index].y += state.configMainImage[index]._dragY;

    state.configMainImage[index].offsetX =
      state.configMainImage[index].width / 2;
    state.configMainImage[index].offsetY =
      state.configMainImage[index].height / 2;

    if (!state.configMainImage[index]._flipped) {
      state.configMainImage[index].x += state.configMainImage[index].offsetX;
    } else {
      state.configMainImage[index].x -= state.configMainImage[index].offsetX;
    }
    state.configMainImage[index].y += state.configMainImage[index].offsetY;

    // state.configMainImage[0].y -= (state.configText.upperText._paintedHeight - state.configText.upperText.padding + state.configBackgroundRect.strokeWidth) / 2
    // state.configText.upperText._paintedHeight - state.configText.upperText.padding + state.configBackgroundRect.strokeWidth / 2

    // console.log("m lowerText._paintedHeight: " + state.configText.lowerText._paintedHeight)

    methods.recalculateWaterMarkImageSize();
    methods.recalculateEveryExtraImageSize();
  },

  textHeightChanged(upperTextHeight, lowerTextHeight) {
    // if the text is empty, its height should be 0
    var correctedUpperTextHeight =
      state.configText.upperText.text.trim() == "" ? 0 : upperTextHeight;
    var correctedLowerTextHeight =
      state.configText.lowerText.text.trim() == "" ? 0 : lowerTextHeight;

    state.configText.upperText._paintedHeight = correctedUpperTextHeight;
    state.configText.lowerText._paintedHeight = correctedLowerTextHeight;

    // console.log(correctedUpperTextHeight)

    // this.recalculateImageSizes();
    this.recalculateEveryImageSize();
    this.alignLowerTextToBottom(lowerTextHeight);
  },

  setText(location, text) {
    state.configText[location].visible = text.length > 0;
    //console.log("set text" + location + " " + text)
    state.configText[location]._rawText = text;
    this._formatText(location);
  },

  setTextColor(location, color) {
    console.log("setTextColor: " + color);
    if (color.type == "redBlack") {
      state.configText[location].firstLetter.visible = true;
    } else {
      state.configText[location].firstLetter.visible = false;
    }

    state.configText[location].fill = color.color;

    console.log("setTextColor: " + state.configText[location].fill);
  },

  alignLowerTextToBottom() {
    // console.log("alignLowerTextToBottom" + lowerTextHeight)
    var y =
      state.configStage.height -
      state.configText.lowerText._paintedHeight -
      // - state.configText.lowerText._paintedHeight
      state.configText.lowerText.padding -
      state.configBackgroundRect.strokeWidth / 2;
    state.configText.lowerText.y = y;
    state.configText.lowerText.firstLetter.y = y;
  },

  _formatText(location) {
    if (state.configText[location]._upperCase) {
      state.configText[location].text = state.configText[
        location
      ]._rawText.toUpperCase();
    } else {
      state.configText[location].text = state.configText[location]._rawText;
    }
  },

  // _outlineImage() {

  //     // console.log("helllo")
  //     var img = state.configMainImage[0].image.src

  //     for (var x = 0; x < img.width; x++) {
  //         for (var y = 0; y < img.width; y++) {
  //             console.log(img[x][y])
  //         }
  //     }
  // },

  /*setFont(location, font) {
        state.configText[location].fontFamily = font
        state.configText[location].firstLetter.fontFamily = font
    },*/

  setFontSize(location, fontSizeInPt) {
    // state.configText[location]._rawFontSizeInPt = fontSizeInPt

    // console.log("conversion ratio> " + state.stageSize.heightConversionRatio)
    // console.log("conversion ratio new> " +            state.configStage.height / 500)
    var fontSizeInPx =
      (fontSizeInPt * 1.333 * state.configStage.height) /
      state.stageSize.originalHeight;

    state.configText[location].fontSize = fontSizeInPx;
    state.configText[location].firstLetter.fontSize = fontSizeInPx;
  },

  // setUpperCase(location, upperCase) {
  //     state.configText[location]._upperCase = upperCase
  //     this._formatText(location)

  //     /*console.log(state["configUpperText"]._rawText + " raw")*/
  // },

  setFirstLetter(location, text, firstRowWidth) {
    let firstLetter = text.substring(0, 1);
    let firstTwoLetter = text.substring(0, 2);
    let firstThreeLetter = text.substring(0, 3);

    let doupleLetters = ["cs", "dz", "gy", "ly", "ny", "sz", "ty", "zs"];
    let trippleLetters = ["dzs"];

    if (trippleLetters.includes(firstThreeLetter.toLowerCase())) {
      firstLetter = firstThreeLetter;
    } else if (doupleLetters.includes(firstTwoLetter.toLowerCase())) {
      firstLetter = firstTwoLetter;
    }

    // let    firstLetter = text[0]

    state.configText[location].firstLetter.text = firstLetter;
    /*state.configText[location].firstLetter.fontSize = state.configText[location].fontSize*/

    /*state.configUpperTextInitial.width = firstRowWidth*/
    state.configText[location].firstLetter.y = state.configText[location].y;

    state.configText[location].firstLetter.x =
      (state.configStage.width - firstRowWidth) / 2 -
      state.configText[location].firstLetter.padding;
    //+ state.configBackgroundRect.strokeWidth/2
    /*console.log(firstLetter + ": " + firstRowWidth)*/
  },

  toggleExtraImage(name, source, type, location) {
    const index = state.extraImageNameList.indexOf(name);

    // this.addExtraIcon(name, type, source)

    if (index > -1) {
      state.extraImageNameList.splice(index, 1);
      state.configExtraImages.splice(index, 1);

      // es nagyon fontos, hogy a meretezes doboz miatt az actualMainImage erteket is masikra allitsuk, ha ez lett volna kijelolve
      if (
        state.actualMainImage.type == "configExtraImages" &&
        state.actualMainImage.index == index
      ) {
        methods.restoreActualMainImageToDefault();
      }
    } else {
      var extraImage = {
        image: null,
        _originalSource: source,
        x: 0,
        y: 0,
        width: 100,
        height: 100,

        draggable: false,

        // stroke: 'black',
        // strokeWidth: 4,

        _name: name,
        _type: type,
        _location: location,

        rotation: 0,
        _flipped: false,

        _zoom: 100,
        _dragX: 0,
        _dragY: 0,
      };

      console.log("source: " + source + " type: " + type);

      extraImage.image = new window.Image();
      extraImage.image.src = source;

      console.log("extraImageName" + name);

      if (name == "bal_sziv" || name == "jobb_kez") {
        state.extraImageNameList.unshift(name);
        state.configExtraImages.unshift(extraImage);
      } else {
        state.extraImageNameList.push(name);
        state.configExtraImages.push(extraImage);
      }
    }

    methods.recalculateEveryExtraImageSize();

    // console.log("state.configExtraImages:");
    // console.log(state.configExtraImages);


// Save to history
var undoFunction = function() {
  methods.toggleExtraImage(name, source, type, location) 
};

methods.saveToHistory(
  "toggleExtraImage",
  undoFunction,
  ""
);
//~Save to hisory

  },
};

// watch(
//   () => state,
//   (newValue, oldValue) => {
//   console.log("State changed!!!")
//   console.log(newValue)
//   console.log(oldValue)
//   stateHistory.push(JSON.stringify(newValue))
//   },
//   {deep: true}
// );



watch(
  () => state.configText.upperText._rawText,
  (newValue, oldValue) => {
    methods.setText("upperText", newValue);

    // Save to history
    var undoFunction = function() {
      state.configText.upperText._rawText = oldValue;
    };

    methods.saveToHistory(
      "state.configText.upperText._rawText",
      undoFunction,
      oldValue
    );
    //~Save to hisory
  },
  { immediate: true }
);

watch(
  () => state.configText.lowerText._rawText,
  (newValue, oldValue) => {
    methods.setText("lowerText", newValue);

    // Save to history
    var undoFunction = function() {
      state.configText.lowerText._rawText = oldValue;
    };

    methods.saveToHistory(
      "state.configText.lowerText._rawText",
      undoFunction,
      oldValue
    );
    //~Save to hisory
  },
  { immediate: true }
);

watch(
  () => state.configText.upperText._rawFillColor,
  (newValue, oldValue) => {
    methods.setTextColor("upperText", newValue);

    // Save to history
    var undoFunction = function() {
      state.configText.upperText._rawFillColor = oldValue;
    };

    methods.saveToHistory(
      "state.configText.upperText._rawFillColor",
      undoFunction,
      oldValue
    );
    //~Save to hisory
  },
  { immediate: true }
);

watch(
  () => state.configText.lowerText._rawFillColor,
  (newValue, oldValue) => {
    methods.setTextColor("lowerText", newValue);

    // Save to history
    var undoFunction = function() {
      state.configText.lowerText._rawFillColor = oldValue;
    };

    methods.saveToHistory(
      "state.configText.lowerText._rawFillColor",
      undoFunction,
      oldValue
    );
    //~Save to hisory
  },
  { immediate: true }
);

watch(
  () => state.configText.upperText.fontFamily,
  (newValue, oldValue) => {
    state.configText.upperText.firstLetter.fontFamily = newValue;

    // Save to history
    var undoFunction = function() {
      state.configText.upperText.fontFamily = oldValue;
    };

    methods.saveToHistory(
      "state.configText.upperText.fontFamily",
      undoFunction,
      oldValue
    );
    //~Save to hisory
  },
  { immediate: true }
);

watch(
  () => state.configText.lowerText.fontFamily,
  (newValue, oldValue) => {
    state.configText.lowerText.firstLetter.fontFamily = newValue;

    // Save to history
    var undoFunction = function() {
      state.configText.lowerText.fontFamily = oldValue;
    };

    methods.saveToHistory(
      "state.configText.lowerText.fontFamily",
      undoFunction,
      oldValue
    );
    //~Save to hisory
  },
  { immediate: true }
);

watch(
  () => state.configText.upperText.fontSize,
  (newValue) => {
    state.configText.upperText.firstLetter.fontSize = newValue;
  },
  { immediate: true }
);

watch(
  () => state.configText.lowerText.fontSize,
  (newValue) => {
    state.configText.lowerText.firstLetter.fontSize = newValue;
  },
  { immediate: true }
);

watch(
  () => state.configText.upperText._upperCase,
  (newValue, oldValue) => {
    methods._formatText("upperText");

    console.log("uppercase: " + newValue);

    // Save to history
    var undoFunction = function() {
      state.configText.upperText._upperCase = oldValue;
    };

    methods.saveToHistory(
      "state.configText.upperText._upperCase",
      undoFunction,
      oldValue
    );
    //~Save to hisory
  },
  { immediate: true }
);

watch(
  () => state.configText.lowerText._upperCase,
  (newValue, oldValue) => {
    methods._formatText("lowerText");

    console.log("uppercase: " + newValue);

    // Save to history
    var undoFunction = function() {
      state.configText.lowerText._upperCase = oldValue;
    };

    methods.saveToHistory(
      "state.configText.lowerText._upperCase",
      undoFunction,
      oldValue
    );
    //~Save to hisory
  },
  { immediate: true }
);

watch(
  () => state.configText.upperText._rawFontSizeInPt,
  (newValue, oldValue) => {
    methods.setFontSize("upperText", newValue);

    // Save to history
    var undoFunction = function() {
      state.configText.upperText._rawFontSizeInPt = oldValue;
    };

    methods.saveToHistory(
      "state.configText.upperText._rawFontSizeInPt",
      undoFunction,
      oldValue
    );
    //~Save to hisory
  },
  { immediate: true }
);

watch(
  () => state.configText.lowerText._rawFontSizeInPt,
  (newValue, oldValue) => {
    methods.setFontSize("lowerText", newValue);

    // Save to history
    var undoFunction = function() {
      state.configText.lowerText._rawFontSizeInPt = oldValue;
    };

    methods.saveToHistory(
      "state.configText.lowerText._rawFontSizeInPt",
      undoFunction,
      oldValue
    );
    //~Save to hisory
  },
  { immediate: true }
);

watch(
  () => state.configGeneral._outline,
  (newValue, oldValue) => {
    console.log("outline: " + newValue);

    // Save to history
    var undoFunction = function() {
      state.configGeneral._outline = oldValue;
    };

    methods.saveToHistory(
      "state.configGeneral._outline ",
      undoFunction,
      oldValue
    );
    //~Save to hisory
  },
  { immediate: true }
);

watch(
  () => state.configGeneral._grayScale,
  (newValue, oldValue) => {
    console.log("grayScale: " + newValue);

    // Save to history
    var undoFunction = function() {
      state.configGeneral._grayScale = oldValue;
    };

    methods.saveToHistory(
      "state.configGeneral._grayScale ",
      undoFunction,
      oldValue
    );
    //~Save to hisory
  },
  { immediate: true }
);

watch(
  () => state.configGeneral._outlineColor,
  (newValue, oldValue) => {
    console.log("outline: " + newValue);

    // Save to history
    var undoFunction = function() {
      state.configGeneral._outlineColor = oldValue;
    };

    methods.saveToHistory(
      "state.configGeneral._outlineColor ",
      undoFunction,
      oldValue
    );
    //~Save to hisory
  },
  { immediate: true }
);

watch(
  () => state.configBackgroundRect._rawFillColor,
  (newValue, oldValue) => {
    state.configBackgroundRect.fill = newValue.color;

    // Save to history
    var undoFunction = function() {
      state.configBackgroundRect._rawFillColor = oldValue;
    };

    methods.saveToHistory(
      "state.configBackgroundRect._rawFillColor",
      undoFunction,
      oldValue
    );
    //~Save to hisory
  },
  { immediate: true }
);

watch(
  () => state.configBackgroundRect._rawStrokeColor,
  (newValue, oldValue) => {
    state.configBackgroundRect.stroke = newValue.color;

    // Save to history
    var undoFunction = function() {
      state.configBackgroundRect._rawStrokeColor = oldValue;
    };

    methods.saveToHistory(
      "state.configBackgroundRect._rawStrokeColor",
      undoFunction,
      oldValue
    );
    //~Save to hisory
  },
  { immediate: true }
);

watch(
  () => state.configBackgroundRect._rawStrokeWidthInPx,
  (newValue, oldValue) => {
    // console.log("newValue> " + newValue)

    var newStrokeWidth =
      (newValue * state.configStage.width) / state.stageSize.originalWidth;

    var delta = newStrokeWidth - state.configBackgroundRect.strokeWidth;

    state.configBackgroundRect.strokeWidth = newStrokeWidth;

    state.configText.upperText.y += delta / 2;
    state.configText.upperText.x += delta / 2;
    state.configText.upperText.width -= delta;

    // methods.alignLowerTextToBottom()
    state.configText.lowerText.y -= delta / 2;
    state.configText.lowerText.x += delta / 2;
    state.configText.lowerText.width -= delta;

    // console.log(delta)

    // methods.recalculateImageSizes()

    // Save to history
    var undoFunction = function() {
      state.configBackgroundRect._rawStrokeWidthInPx = oldValue;
    };

    methods.saveToHistory(
      "state.configBackgroundRect._rawStrokeWidthInPx",
      undoFunction,
      oldValue
    );
    //~Save to hisory
  },
  { immediate: true }
);

watch(
  () => state[state.actualMainImage.type][state.actualMainImage.index]._zoom,
  (newValue, oldValue) => {
    if (state.actualMainImage.type == "configMainImage") {
      methods.recalculateImageSizes(state.actualMainImage.index);
    } else if (state.actualMainImage.type == "configExtraImages") {
      methods.recalculateEveryExtraImageSize();
    }

    console.log("_zoom" + newValue);

    // Save to history
    var undoFunction = function() {
      state[state.actualMainImage.type][
        state.actualMainImage.index
      ]._zoom = oldValue;
    };

    methods.saveToHistory(
      "state[state.actualMainImage.type][state.actualMainImage.index]._zoom",
      undoFunction,
      oldValue
    );
    //~Save to hisory
  },
  { immediate: true }
);

const dragHistory = { x: undefined, y: undefined };
// this function waits before saving the drag event to recieve the x and y coordinates (it receives it with two calls)
function saveDragToHistory(x, y) {
  if (x != undefined) {
    dragHistory.x = x;
  }
  if (y != undefined) {
    dragHistory.y = y;
  }

  if (dragHistory.x != undefined && dragHistory.y != undefined) {
    // Make a copy
    let dragHistoryCopy = { x, y };
    dragHistoryCopy.x = dragHistory.x;
    dragHistoryCopy.y = dragHistory.y;

    // Save to history
    var undoFunction = function() {
      state[state.actualMainImage.type][state.actualMainImage.index]._dragX =
        dragHistoryCopy.x;

      state[state.actualMainImage.type][state.actualMainImage.index]._dragY =
        dragHistoryCopy.y;
    };

    methods.saveToHistory("_drag", undoFunction, dragHistory);

    console.log("save drag to history ");
    console.log(dragHistory);
    //~Save to hisory

    dragHistory.x = undefined;
    dragHistory.y = undefined;
  }
}

watch(
  () => state[state.actualMainImage.type][state.actualMainImage.index]._dragX,
  (newValue, oldValue) => {
    console.log("dragX: " + newValue);

    // Save to history
    saveDragToHistory(oldValue, undefined);
    //~Save to hisory

    if (state.actualMainImage.type == "configMainImage") {
      methods.recalculateImageSizes(state.actualMainImage.index);
    } else if (state.actualMainImage.type == "configExtraImages") {
      // methods.recalculateExtraImageSize(state.actualMainImage.index);
      methods.recalculateEveryExtraImageSize();
    }
  },
  { immediate: true }
);

watch(
  () => state[state.actualMainImage.type][state.actualMainImage.index]._dragY,
  (newValue, oldValue) => {
    console.log("dragY: " + newValue);

    // Save to history
    saveDragToHistory(undefined, oldValue);
    //~Save to hisory

    if (state.actualMainImage.type == "configMainImage") {
      methods.recalculateImageSizes(state.actualMainImage.index);
    } else if (state.actualMainImage.type == "configExtraImages") {
      // methods.recalculateExtraImageSize(state.actualMainImage.index);
      methods.recalculateEveryExtraImageSize();
    }
  },
  { immediate: true }
);

// watch(
//   () => state.configExtraImages[state.actualMainImage.index]._zoom,
//   () => {
//       methods.recalculateExtraImageSizes();
//   },
//   { immediate: true }
// );

// watch(
//   () => state.configMainImage[state.actualMainImageIndex].draggable,
//   (newValue) => {
//     console.log(newValue)

//     // a tobbi kep eseteben kikapcsoljuk a mozgathatosagot
//     for(let i = 0; i<state.configMainImage.length; i++)
//     {
//       if(i!=state.actualMainImageIndex)
//       {
//       state.configMainImage[i].draggable = false// newValue
//       }
//     }
//   },
//   { immediate: true }
// );

// watch(
//  () => state.configMainImage,
//   (newValue, oldValue) => {

//     if(newValue.length > oldValue.length)
//     {
//     console.log("///valtozott a meret!!!")
//     }
//     else
//     {
//       console.log("///nem valtozott!!!")
//     }

//     console.log(newValue)
//     console.log(oldValue)
//   },

//   {deep: true}
// ); // {flush: post} only triggers callback after DOM update

watch(
  () => state.actualMainImage,
  (newValue, oldValue) => {
    // actualMainImageIsOnTop = false

    // console.log("actualMainImageIndex" + newValue)
    if (state[oldValue.type][oldValue.index].draggable) {
      state[oldValue.type][oldValue.index].draggable = false;
      state[newValue.type][newValue.index].draggable = true; // newValue
    }

    // Save to history
    var undoFunction = function() {
      state.actualMainImage.index = oldValue.index;
      state.actualMainImage.type = oldValue.type;
    };

    methods.saveToHistory("state.actualMainImage", undoFunction, oldValue);
    //~Save to hisory
  }
  // { immediate: true }
);

watch(
  () => state[state.actualMainImage.type][state.actualMainImage.index]._flipped,
  (newValue, oldValue) => {
    methods.recalculateImageSizes(state.actualMainImage.index);

    console.log("flipped: " + newValue);

    // Save to history
    var undoFunction = function() {
      state[state.actualMainImage.type][
        state.actualMainImage.index
      ]._flipped = oldValue;
    };

    methods.saveToHistory(
      "state[state.actualMainImage.type][state.actualMainImage.index]._flipped",
      undoFunction,
      oldValue
    );
    //~Save to hisory
  },
  { immediate: true }
);

watch(
  () =>
    state[state.actualMainImage.type][state.actualMainImage.index].draggable,
  (newValue) => {
    // console.log('moving turned on ' + newValue)
    if (newValue) {
      state.configPaintBucket._on = false;
    }

    // // Save to history
    // var undoFunction = function() {
    //   state[state.actualMainImage.type][state.actualMainImage.index].draggable = oldValue;
    // };

    // methods.saveToHistory(
    //   "state[state.actualMainImage.type][state.actualMainImage.index].draggable",
    //   undoFunction, oldValue
    // );
    // //~Save to hisory
  },
  { immediate: true }
);

watch(
  () => state[state.actualMainImage.type][state.actualMainImage.index].rotation,
  (newValue, oldValue) => {
    console.log("rotation: " + newValue);

    // Save to history
    var undoFunction = function() {
      state[state.actualMainImage.type][
        state.actualMainImage.index
      ].rotation = oldValue;
    };

    methods.saveToHistory(
      "state[state.actualMainImage.type][state.actualMainImage.index].rotation",
      undoFunction,
      oldValue
    );
    //~Save to hisory
  },
  { immediate: true }
);

watch(
  () => state.configPaintBucket._on,
  (newValue) => {
    if (newValue) {
      state[state.actualMainImage.type][
        state.actualMainImage.index
      ].draggable = false;
    }
  },
  { immediate: true }
);

watch(
  () => state.configWaterMarkImage.visible,
  (newValue, oldValue) => {
    console.log("watermark " + newValue);

    // Save to history
    var undoFunction = function() {
      state.configWaterMarkImage.visible = oldValue;
    };

    methods.saveToHistory(
      "state.configWaterMarkImage.visible",
      undoFunction,
      oldValue
    );
    //~Save to hisory
  },
  { immediate: true }
);

// watch(
//     () => state,
//     (newValue, oldValue) => {
//         // if (newValue) {
//         //     state.configMainImage[0].draggable = false
//         // }

//         console.log(newValue)
//         console.log(oldValue)

//         for (var key of Object.keys(newValue)) {
//            // console.log(key + " -> " + newValue[key])

//         //    if(newValue[key] != oldValue[key])
//         //    {
//                localStorage.setItem("canvasStore_" + key, JSON.stringify(newValue[key]))
//         //    }
//         }

//     },
//     {deep:true}
// );

// watch(
//     () => state,
//     (newValue) => {

//                localStorage.setItem("canvasStore", JSON.stringify(newValue))

//     },
//     {deep:true}
// );

export default {
  // defaultState,
  state /*: readonly(state)*/,
  defaultState: readonly(defaultState),
  stateHistory,
  methods,
  defaultImageResolution,
};
